import { FormArray, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Permission } from "./enums/permissions";
import { User } from "firebase/auth";

import * as moment from "moment";
export function validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        if (control instanceof UntypedFormControl) {
            control.markAsTouched({ onlySelf: true });
            control.markAsDirty({ onlySelf: true });
        } else if (control instanceof UntypedFormGroup) {
            validateAllFormFields(control);
        } else if (control instanceof FormArray) {
            control.controls.forEach(item => {
                if (item instanceof UntypedFormControl) {
                    item.markAsTouched({ onlySelf: true });
                    item.markAsDirty({ onlySelf: true });
                }
                else {
                    let itemGroup: UntypedFormGroup = item as UntypedFormGroup;
                    validateAllFormFields(itemGroup);
                }
            });
        }
    });
}

export function toTree(list) {
    var map = {}, node, roots = [], i;

    for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
    }

    for (i = 0; i < list.length; i += 1) {
        node = list[i];
        node.type = "body";
        node.data = list[i];
        if (node.parentId !== null) {
            // if you have dangling branches check that map[node.parentId] exists
            list[map[node.parentId]].children.push(node);
        } else {
            roots.push(node);
        }
    }
    return roots;
}
/******** Fechas ***********/
export function toDateOnlyString(date: Date) {
    if (!date)
        return null;
    else
        return `${date.getFullYear()}-${returnMonth()}-${returnDay()}`;

    function returnDay() {
        var d = (date.getDate() < 10) ? "0" + (date.getDate()) : date.getDate();
        return d;
    }
    function returnMonth() {
        var m = (date.getMonth() + 1 < 10) ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
        return m;
    }
}

export function convertDateToLocaleISOString(date: Date): string {
    const tzo = -date.getTimezoneOffset();
    const dif = tzo >= 0 ? '+' : '-';
    const pad = (num: number) => {
        const norm = Math.floor(Math.abs(num));
        return (norm < 10 ? '0' : '') + norm;
    };
    return date.getFullYear()
        + '-' + pad(date.getMonth() + 1)
        + '-' + pad(date.getDate())
        + 'T' + pad(date.getHours())
        + ':' + pad(date.getMinutes())
        + ':' + pad(date.getSeconds())
        + dif + pad(tzo / 60)
        + ':' + pad(tzo % 60);
}


export function checkStringDate(date: string, dateFormat: string): boolean {

    let newDate = moment(date, dateFormat);
    return newDate.isValid() && (moment(newDate).format(dateFormat) == date);

}

//Obtiene la hora local
export function getLocalDate(date) {

    const now = new Date();
    var tempDate = new Date(date);
    return new Date(tempDate.getTime() - (now.getTimezoneOffset() * 60000));
}

/******** Tiempo ***********/
export function checkStringTime(time: string): boolean {
    let result = false;
    if (time && time.indexOf(":") > -1) {
        let timeParts = time.replace(/\s/g, "").split(":");

        if (timeParts.length < 3)
            return false;

        let hours = Number(timeParts[0]);
        let minutes = Number(timeParts[1]);
        let seconds = Number(timeParts[2]);
        result = !isNaN(hours)
            && !isNaN(minutes) && minutes <= 59
            && !isNaN(seconds) && seconds <= 59;
    }

    return result;
}
export function formatStringTime(time: string): string {
    let result = null;
    if (time && time.indexOf(":") > -1) {
        let timeParts = time.replace(/\s/g, "").split(":");
        let hasError = timeParts.findIndex(f => isNaN(Number(f))) >= 0;

        if (!hasError) {

            let hours = timeParts.length == 3 ? normalizeTimePart(timeParts[0]) : "00";
            let minutes = timeParts.length == 3 ? normalizeTimePart(timeParts[1]) : normalizeTimePart(timeParts[0]);
            let seconds = timeParts.length == 3 ? normalizeTimePart(timeParts[2]) : normalizeTimePart(timeParts[1]);
            result = `${hours}:${minutes}:${seconds}`;
        }
    }

    return result;
}
function normalizeTimePart(time: string) {

    if (time.length > 2) {
        return time.substring(0, 2);
    }
    else if (time.length == 2) {
        return time;
    }
    else if (time.length == 1) {
        return `0${time}`;
    }
    else {
        return `00`;
    }

}
/***************** */
export function getUserRoles(claims): Permission[] {
    let permissions = [];
    if (claims) {
        const perm_admin = claims.extension_role_admin;
        const perm_training_manager = claims.extension_role_training_manager;
        const perm_training_manager_budgets = claims.extension_role_training_manager_budgets;
        const perm_job_exchange = claims.extension_role_job_exchange;
        const perm_vca = claims.extension_role_vca;
        const perm_vca_budgets = claims.extension_role_vca_budgets;

        if (perm_admin) {
            permissions.push(Permission.Admin);
        }
        if (perm_training_manager) {
            permissions.push(Permission.TrainingManager);
        }
        if (perm_training_manager_budgets) {
            permissions.push(Permission.TrainingManagerBudgets);
        }
        if (perm_job_exchange) {
            permissions.push(Permission.JobExchange);
        }
        if (perm_vca) {
            permissions.push(Permission.VCA)
        }
        if (perm_vca_budgets) {
            permissions.push(Permission.VCABudgets)
        }
    }

    return permissions;
}

/********** Objetos ********* */
export function deepCopy(oldObj: any) {
    var newObj = oldObj;
    if (oldObj && typeof oldObj === "object") {
        if (oldObj instanceof Date) {
            return new Date(oldObj.getTime());
        }
        newObj = Object.prototype.toString.call(oldObj) === "[object Array]" ? [] : {};
        for (var i in oldObj) {
            newObj[i] = deepCopy(oldObj[i]);
        }
    }
    return newObj;
}

/***************** */
export function normalizeName(name: string) {
    return name ? name.toLocaleLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        .replace(/\s/g, "")
        .replace(/[-_\s]/gi, "")
        : name;
}

function splitCapitalize(word: string, splitChart: string) {
    if (word) {
        var splitStr = word.split(splitChart);
        for (var j = 0; j < splitStr.length; j++) {
            splitStr[j] = splitStr[j].charAt(0).toUpperCase() + splitStr[j].substring(1);
        }
        word = splitStr.join(splitChart);
    }
    return word;
}
/****************************/
export function formatErrorMessage(error): string {
    if (error?.error?.errors) {
        return error.error.errors.join('. ');
    }

    return "";
}
export function getInitials(user: User) {
    let initials = "";
    let name = "";
    if (user.displayName) {
        name = user.displayName;
    }
    else {
        name = user.email;
    }
    let initialsArray = name.split(' ');
    if (initialsArray.length > 1) {
        initials += initialsArray[0][0] + initialsArray[1][0];
    }
    else {
        initials += initialsArray[0][0] + initialsArray[0][1];
    }
    return initials;
}

export function getDisplayName(user: User) {
    if (user.displayName) {
        return user.displayName;
    }
    else {
        return user.email;
    }
}

